import {
  MagnetStraight,
  ChartPolar,
  CurrencyCircleDollar,
  ChatsCircle,
} from "@phosphor-icons/react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import { useState } from "react";

import 'swiper/css';
import 'swiper/css/pagination';

const Perks = () => {
  const [setActive] = useState(false)
  
  const cards = [
    {title: 'Leads', text: 'From the knowledge of your product and service to the purchase, we make a point of bringing tailored customers to your company.', icon: <MagnetStraight/>},
    {title: 'Precision', text: 'We start with a deep analysis of your brand and product to align really efficient and personalized strategies for the success of your campaigns.', icon: <ChartPolar/>},
    {title: 'ROI', text: "By meticulously tracking performance metrics and continuously refining our approaches, we ensure that your marketing budget is allocated wisely, delivering exceptional ROI", icon: <CurrencyCircleDollar/>},
    {title: 'Communication', text: 'We understand the importance of clear and timely communication, which is why we provide regular updates, detailed reports, and proactive feedback throughout our engagement.', icon: <ChatsCircle/>},
  ];
  return (
    <div className="bg-beige-light block justify-center py-12 px-5 md:px-52 md:py-16" id="perks">
      <h1 className="text-black text-2xl font-extrabold text-center mb-12">Our Mission to Skyrocket Your Success</h1>
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={0}
        slidesPerView={4}
        pagination={{ clickable: true }}
        onSlideChange={() => console.log('nothing')}
        onSwiper={(swiper) => console.log(swiper)}
        modules={[Autoplay]}
        breakpoints={
           {
            320: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            480: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 40
            }
          }
        }
      >
        {
          cards.map((card, i)=> (
            <SwiperSlide key={i}>
              <div className="md:h-96 flex justify-center">
                <div  
                  onClick={() => setActive(i)}
                  className={`card card-active`}
                >
                  <div className="card-icon flex`">
                    {card.icon}
                  </div>
                  <h2 className="text-3xl mt-2 font-semibold">{card.title}</h2>
                  <p className="card-text">{card.text}</p>
                </div>
              </div>
            </SwiperSlide>
          ))         
        }
      </Swiper>
      <div className="block text-center pt-12 px-5 md:px-52 md:pt-16">
        <h2 className="text-xl font-semibold my-5 text-gray-400">We seek breathtaking results:</h2>
        <p className="text-center font-semibold mb-8">Here at Saint Digital we seek partnerships with the best in the market and we aim really high for our clients always thinking about bringing the maximum return on each ad spend.</p>
        <a href="https://SaintDigitalAgency.as.me/" target="_blank" rel="noreferrer" className="justify-center rounded-none-xl mt-8 px-5 py-2 bg-orange-light text-beige-light hover:bg-orange-light hover:text-olive-light hover:font-extrabold hover:ease-in-out transition duration-300">
          Get in contact!
        </a>
      </div>
    </div>
  );
};

export default Perks;
