import {
  GoogleLogo,
  InstagramLogo,
  YoutubeLogo,
  TiktokLogo,
  FacebookLogo,
  SnapchatLogo,
  VideoCamera,
  Camera,
  NotePencil,
  WhatsappLogo,
  UserPlus,
  ThumbsUp,
  PresentationChart,
  TrendUp,
  MapTrifold,
  UsersFour
} from "@phosphor-icons/react";

const Services = () => {

  const services = [
    {title: 'Run Ads & Paid Traffic', text: "We strategize, create, and manage high-impact ad campaigns tailored to your business goals. From crafting captivating ad copies to precise audience targeting, we ensure your message reaches the right people at the right time. Maximize conversions and amplify your online presence with our expert touch.", icons: [<GoogleLogo/>, <InstagramLogo/>, <YoutubeLogo/>, <TiktokLogo/>, <FacebookLogo/>, <SnapchatLogo/>, <WhatsappLogo/>]},
    {title: 'Social Media Management', text: "Supercharge your brand's online presence with our Social Media Management services! We handle the hashtags, posts, and engagement so you can focus on what you do best. Elevate your social game with us today!", icons: [ <UserPlus/>, <PresentationChart/>, <TrendUp/>, <MapTrifold/>, <UsersFour/>]},
    {title: 'Content consulting', text: "We dive deep into your brand's identity, analyzing your goals and audience to provide personalized content strategies. From ideation and creation to optimization, we guide you towards compelling content that resonates and drives engagement. Elevate your brand narrative with our strategic insights.", icons: [<VideoCamera/>, <Camera/>, <NotePencil/>,<ThumbsUp/>]},
  ]

  return(
    <div className="px-5 py-12 bg-olive-dark" id="service">
      <div className="text-center">
        <h1 className="text-xl font-extralight text-gray-400 mb-2">Our Services</h1> 
        <h1 className="text-orange-light text-4xl font-extrabold mb-2">What we offer</h1>
      </div>
      <div className="md:h-92 md:w-9/12 m-auto">
          {
            services.map((service, i) => (
            <div className="flex justify-center my-12">
              <div className="md:w-9/12 bg-olive-light p-7">
                <h1 className="text-3xl font-extrabold text-orange-light mb-2">{service.title}</h1>
                <h2 className="text-2xl text-white mb-4">{service.text}</h2>
                <div className="flex text-white text-4xl">
                  {service.icons}
                </div>
              </div>
            </div>
            ))
          }
        </div>
    </div>
  )
}

export default Services