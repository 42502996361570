import {Link} from 'react-scroll';
import SDlogoOlive from "../../imgs/saintdigitalolivelogo.png";

const Navbar = () => {
  return (
    <nav class="bg-olive-dark dark:bg-gray-900 fixed w-full z-20 top-0 left-0 shadow-sm">
      <div class="md:container flex flex-wrap items-center justify-between p-4">
        <div className="hidden md:flex">
          <Link to="hero" spy={true} smooth={true} offset={0} duration={500}>
            <span className="text-2xl font-bold text-white hover:text-orange-light hover:ease-in-out transition duration-300 mr-10">Menu.</span>
          </Link>
          <Link to="contact" spy={true} smooth={true} offset={50} duration={500}>
            <span className=" text-2xl font-bold text-white hover:text-orange-light hover:ease-in-out transition duration-300">Contact.</span>
          </Link>
        </div>
        <Link to="hero" spy={true} smooth={true} offset={0} duration={500}>
          <span class="flex items-center">
            <img src={SDlogoOlive} class="h-8 mr-3" alt="Saint Digital Logo" />
          </span>
        </Link>
        <div class="flex">
          <span className="font-bold md:hidden text-white hover:text-orange-light hover:ease-in-out transition duration-300">Contact.</span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
