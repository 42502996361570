import {Link} from 'react-scroll';

const Hero = () => {
  return (
    <div className="flex flex-col justify-start group font-bold text-2xl h-screen md:font-normal md:text-4xl px-5 pt-28 pb-4 md:pt-22 md:mx-52" id="hero">
      <Link to="agency" spy={true} smooth={true} offset={-20} duration={500} className="block mb-10 md:mb-10 text-white group-hover:brightness-50 hover:underline hover:decoration-orange-light group-hover:hover:brightness-100">
        00. The digital agency
      </Link>
      <Link to="perks" spy={true} smooth={true} offset={0} duration={500} className="block mb-10 md:mb-10 text-white group-hover:brightness-50 hover:underline hover:decoration-orange-light group-hover:hover:brightness-100">
        01. What we do
      </Link>
      <Link to="perks" spy={true} smooth={true} offset={500} duration={500} className="block mb-10 md:mb-10 text-white group-hover:brightness-50 hover:underline hover:decoration-orange-light group-hover:hover:brightness-100 text-left">
        02. We aim high for your business
      </Link>
      <Link to="perks" spy={true} smooth={true} offset={500} duration={500} className="block mb-10 md:mb-10 text-white group-hover:brightness-50 hover:underline hover:decoration-orange-light group-hover:hover:brightness-100">
        03. ROI driven
      </Link>
      <Link to="service" spy={true} smooth={true} offset={-10} duration={500} className="block mb-10 md:mb-10 text-white group-hover:brightness-50 hover:underline hover:decoration-orange-light group-hover:hover:brightness-100">
        04. Services
      </Link>
      <Link to="process" spy={true} smooth={true} offset={0} duration={500} className="block mb-10 md:mb-10 text-white group-hover:brightness-50 hover:underline hover:decoration-orange-light group-hover:hover:brightness-100">
        05. Our process
      </Link>
      <Link to="hero" spy={true} smooth={true} offset={0} duration={500} className="block mb-5 md:mb-10 text-white group-hover:brightness-50 hover:underline hover:decoration-orange-light group-hover:hover:brightness-100">
        06. Clients
      </Link>
      <a href="https://SaintDigitalAgency.as.me/" target="_blank" rel="noreferrer" className="justify-center w-fit rounded-none-xl p-5 bg-orange-light text-beige-light hover:bg-orange-light hover:text-olive-light hover:font-extrabold hover:ease-in-out transition duration-300">
            Get in contact!
      </a>
    </div>
  );
};

export default Hero;
