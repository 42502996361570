import React, { useState } from "react";

const Process = () => {
  const steps = [
    {number: '1', title: 'Onboarding', text: "If you've had prior experience collaborating with other B2B enterprises, you probably know the intricacies involved in integrating your business into their framework. At our core, we invest a significant amount of time to ensure that our onboarding procedure is not only efficient but also remarkably smooth. Gone are the days of having to request access to platforms and guidelines on branding five weeks after we commence our collaboration. By that juncture, all you'll desire is to relish the outcomes of our concerted efforts, while our sole focus remains steadfastly on aiding you in achieving your aspirations"},
    {number: '2', title: 'Strategy',text: "Once seamlessly incorporated into our operations, we will collaboratively assess your present situation and explore your envisioned path for the company in the upcoming months. Following this, we'll creatively deconstruct the necessary KPIs, setting the foundation for constructing the remarkable edifice of your goals."},
    {number: '3', title: 'Deliverables',text: "This forms the essence of our collaboration. Setting objectives, achieving them, and then embarking on the cycle once more."},
    {number: '4', title: 'Pace',text: "Achieving greatness requires patience and dedication. This is why I've established a clear and structured timeline for our collaboration. In the beginning, our focus is on thorough testing – exploring various audiences, strategies, content types, platforms, and all the intricate details. Once we've gained insights into what's effective and what's not, the next step involves eliminating any unnecessary elements and allowing the true champions to thrive on their own merits."},
  ]

  const [collapsedStates, setCollapsedStates] = useState(Array(steps.length).fill(true));

  const toggleCollapsed = (index) => {
    const newCollapsedStates = [...collapsedStates];
    newCollapsedStates[index] = !newCollapsedStates[index];
    setCollapsedStates(newCollapsedStates);
  };

  return (
    <div className="bg-beige-light py-10 px-5 md:p-20" id="process">
      <div className="text-center mb-12">
        <h1 className="text-xl font-extralight text-gray-400 mb-2">First Steps</h1> 
        <h1 className="text-orange-light text-4xl font-extrabold mb-2">How It Works</h1>
      </div>
      <div className="md:mr-20 md:mx-40">
        {steps.map((step, index) => (
          <div key={index} className="mb-12">
            <div className="flex align-middle justify-start mb-2">
              <button className="flex align-middle hover:text-orange-light" onClick={() => toggleCollapsed(index)}>
                <div className="align-middle mr-2 md:mr-5 rounded-full w-8 md:w-10 bg-olive-light">
                  <span className="text-white text-2xl md:text-4xl font-extrabold">{step.number}</span>
                </div>
                <span className="text-2xl md:text-5xl align-middle font-extrabold">{step.title}</span>
              </button>
            </div>
            <div className={`${collapsedStates[index] ? 'hidden' : 'flex'} align-middle mb-12`}>
              <h1 className="text-xl">{step.text}</h1>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <a href="https://SaintDigitalAgency.as.me/" target="_blank" rel="noreferrer" className="justify-center rounded-none-xl mt-8 px-5 py-2 bg-orange-light text-beige-light hover:bg-orange-light hover:text-olive-light hover:font-extrabold hover:ease-in-out transition duration-300">
          Get in contact!
        </a>
      </div>
    </div>
    
  )
}

export default Process;