const Agency = () => {
  return (
    <div className="block bg-olive-light font-bold py-12 px-5 md:px-52 md:py-16 md:font-normal md:text-4xl" id="agency">
      <div className="text-center">
        <span className="text-beige-light text-5xl max-w-md underline decoration-orange-light">
          We Build Your Digital Presence, Block By Block
        </span>
        <h2 className="text-beige-light mt-5 text-2xl">
          We are a dynamic SMMA dedicated to empowering businesses with
          impactful digital marketing solutions. With our strategic approach, we
          help brands harness the full potential of the online landscape. From{" "}
          <mark className="bg-orange-light text-beige-light">
            crafting engaging content
          </mark>{" "}
          to{" "}
          <mark className="bg-orange-light text-beige-light">
            managing advertising campaigns
          </mark>
          , we are committed to driving growth, expanding reach, and delivering
          measurable results. Partner with us to take your brand's online
          presence to new heights.
        </h2>
      </div>

      <div className="relative mx-auto mt-10 border-gray-800 dark:border-gray-800 bg-gray-800 border-[8px] rounded-t-xl h-[172px] max-w-[301px] md:h-[294px] md:max-w-[512px]">
        <div className="rounded-lg overflow-hidden h-[156px] md:h-[278px] bg-white dark:bg-gray-800">
          <img
            src="https://flowbite.s3.amazonaws.com/docs/device-mockups/laptop-screen.png"
            className="dark:hidden h-[156px] md:h-[278px] w-full rounded-xl"
            alt=""
          />
          <img
            src="https://flowbite.s3.amazonaws.com/docs/device-mockups/laptop-screen-dark.png"
            className="hidden dark:block h-[156px] md:h-[278px] w-full rounded-lg"
            alt=""
          />
        </div>
      </div>
      <div className="relative mx-auto bg-gray-900 dark:bg-gray-700 rounded-b-xl rounded-t-sm h-[17px] max-w-[351px] md:h-[21px] md:max-w-[597px]">
        <div className="absolute left-1/2 top-0 -translate-x-1/2 rounded-b-xl w-[56px] h-[5px] md:w-[96px] md:h-[8px] bg-gray-800"></div>
      </div>

      <div className="flex justify-center mt-12">
          <a href="https://SaintDigitalAgency.as.me/" target="_blank" rel="noreferrer" className="justify-center rounded-none-xl p-5 bg-orange-light text-beige-light hover:bg-orange-light hover:text-olive-light hover:font-extrabold hover:ease-in-out transition duration-300">
            Get in contact!
          </a>
      </div>
    </div>
  );
};

export default Agency;
