import './App.css';
import ROI from './components/Roi/roi';
import Agency from './components/agency/agency';
import Footer from './components/footer/footer';
import Hero from './components/hero/hero';
import Navbar from './components/navbar/navbar';
import Perks from './components/perks/perks';
import Process from './components/process/process';
import Services from './components/services/services';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Agency />
      <Perks />
      <ROI />
      <Services />
      <Process />
      <Footer />
    </div>
  );
}

export default App;
