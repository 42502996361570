const ROI = () => {
  return(
    <div className="bg-beige-light md:flex justify-evenly items-center pb-10 border-0" id="roi">
      <div className="block bg-olive-light p-10 text-center shadow-lg">
        <h1 className="text-orange-light text-9xl font-extrabold mb-2">3.0x</h1>
        <h2 className="text-orange-light text-2xl font-extrabold">Our avg ROI</h2>
      </div>
      <div className="p-5 md:w-72 text-center md:text-start">
        <h1 className="text-center text-2xl font-bold mb-2 mt-5">Our favorite metric!</h1>
        <p className="text-xl">Here we will be directly committed to your strategy and responsible for your return on investment. We don't just want to be an agency, but to have the best possible results in the market!</p>
      </div>
    </div>
  )
}

export default ROI